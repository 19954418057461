import React, { useEffect, useState } from 'react';

import { Stack } from '@mui/material';

import dayjs from 'dayjs';

import { StyledDistributionChart, StyledLoader } from '@/components/lib';
import { OvertimeGraphNoDataImage } from '@/components/Overview/OverviewNoVersionSelected/OverviewNoVersionSelected.styles';

import {
  EnvType,
  getPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost,
  PropertiesMonitorSchema,
  PropertyDefinitionSchema
} from '@/helpers/services/api';

import { constants } from '../propertyDrillDown.constants';

interface PropertyDrillDownProductionChartProps {
  isProd: boolean;
  versionId: number;
  selectedEnv: EnvType;
  propertyInfo: PropertyDefinitionSchema;
  timestamp: { start: number; end: number };
}

const { distributionOverTime } = constants;

export const PropertyDrillDownProductionChart = (props: PropertyDrillDownProductionChartProps) => {
  const { timestamp, versionId, selectedEnv, isProd, propertyInfo } = props;

  const [propertyMonitorData, setPropertyMonitorData] = useState<PropertiesMonitorSchema>();
  const [isDistributionOvertimeLoading, setIsDistributionOvertimeLoading] = useState(false);

  const monitorLabels = Array?.isArray(propertyMonitorData?.window_list)
    ? propertyMonitorData?.window_list?.map(window =>
        dayjs(window).format(propertyMonitorData?.frequency === 'HOUR' ? 'DD/MM LT' : 'll')
      )
    : [];

  const monitorData = Array.isArray(propertyMonitorData?.values)
    ? propertyMonitorData?.values?.map(val => Number(val?.avg_value))
    : [];

  useEffect(() => {
    if (isProd) {
      setIsDistributionOvertimeLoading(true);
      getPropertyMonitorApiV1ApplicationVersionsApplicationVersionIdGetPropertyMonitorPost(
        versionId,
        {
          end_time_epoch: timestamp.end,
          start_time_epoch: timestamp.start,
          tz: `${Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone}`
        },
        {
          env_type: selectedEnv as EnvType,
          property_name: propertyInfo?.property_name,
          property_type: propertyInfo?.property_type
        }
      ).then(res => {
        setPropertyMonitorData(res);
        setIsDistributionOvertimeLoading(false);
      });
    }
  }, [selectedEnv, timestamp?.end, timestamp?.start, versionId]);

  if (isDistributionOvertimeLoading) {
    return <StyledLoader />;
  }

  const isNoData = monitorData?.every(val => Number.isNaN(val));

  return (
    <Stack>
      {isNoData && <OvertimeGraphNoDataImage />}
      <StyledDistributionChart
        data={monitorData}
        labels={monitorLabels}
        width={distributionOverTime.width}
        margin={distributionOverTime.margin}
        average={{ value: Number(propertyMonitorData?.total_avg), direction: 'horizontal' }}
      />
    </Stack>
  );
};
