import React from 'react';

import { Stack } from '@mui/material';

import { StyledToolTip } from '@/components/lib';
import {
  CategoricalSelectionsContainer,
  PropertyCategoryPercentage
} from './PropertyDrillDownCategoricalSelections.styles';

import { getAppStorage } from '@/helpers/utils/localStorage';
import { CategoryPropertyValue, useGetAppVersionProdSummary } from '@/helpers/services/api';

interface CategoricalSelectionsProps {
  categories: CategoryPropertyValue[];
  selectedCategory: string | undefined;
  setSelectedCategory: (selectedCategory: string | undefined) => void;
}

const constants = {
  categoryLabel: (category: string | null | undefined, percentage: number, samples: number) =>
    category ? `${Math.round(percentage * 100)}%  ${category === null ? 'N/A' : String(category)} (${samples})` : ''
};

export const PropertyDrillDownCategoricalSelections = (props: CategoricalSelectionsProps) => {
  const { categories, selectedCategory, setSelectedCategory } = props;

  const { versionId, type } = getAppStorage();

  const { data } = useGetAppVersionProdSummary(versionId, { environment: type }); // Used only to get the total number of samples

  const totalCount = data?.n_of_samples || 1;

  return (
    <CategoricalSelectionsContainer data-testid="CategoricalSelectionsContainer">
      {Array.isArray(categories) &&
        categories.map(category => (
          <StyledToolTip
            key={category?.name}
            text={constants.categoryLabel(category.name, category.count / totalCount, category.count)}
          >
            <Stack>
              <PropertyCategoryPercentage
                key={category.name}
                percentage={category.count / totalCount}
                selected={selectedCategory === category.name}
                text={constants.categoryLabel(category.name, category.count / totalCount, category.count)}
                onClick={() => setSelectedCategory(category.name as string)}
              />
            </Stack>
          </StyledToolTip>
        ))}
    </CategoricalSelectionsContainer>
  );
};
