import { styled } from '@mui/material';

import { StyledContainer } from '@/components/lib';

import { isHadron } from '@/helpers/utils/isHadron';

export const OverviewInnerGrid = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'pentest'
})<{ pentest?: boolean }>(({ pentest, theme }) => ({
  gap: '24px',
  display: 'grid',
  padding: '16px 0',
  gridTemplateRows: 'none',
  gridTemplateColumns: pentest ? 'auto' : 'auto 416px',
  maxWidth: isHadron() ? '1800px' : 'auto',

  [theme.breakpoints.down('xl')]: { display: 'flex' }
}));

export const SummaryTopicsContainer = styled(StyledContainer)(({ theme }) => ({
  gap: '24px',
  padding: '0',
  display: 'grid',

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: isHadron() ? 'auto' : 'auto 530px'
  }
}));

export const OverViewPropertiesContainer = styled(StyledContainer)(({ theme }) => ({
  gap: '24px',
  padding: '0',

  [theme.breakpoints.down('xl')]: { gap: '24px' }
}));
