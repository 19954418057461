import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import dayjs from 'dayjs';

import { StyledComboChart, StyledLoader, StyledText } from '@/components/lib';
import {
  AnnotationOvertimeGraphContainer,
  AnnotationOvertimeGraphHeaderContainer,
  OvertimeGraphNoDataImage
} from '../OverviewNoVersionSelected/OverviewNoVersionSelected.styles';

import { getPercentage } from '@/helpers/utils/getPercentage';
import {
  AnnotationsMonitorSchema,
  EnvType,
  getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost
} from '@/helpers/services/api';

interface AnnotationOvertimeGraphProps {
  envType: EnvType;
  selectedVersionId: number;
  timestamp: { start: number; end: number };
}

const constants = {
  score: ' | Score ',
  title: 'Overtime Status',
  graphStyles: { margin: '0 auto' },
  legends: ['User Annotation', 'Estimated Annotation', 'Unknown'],
  graphSizes: { width: '98% !important', height: '400px !important' },
  distribution: (interactions?: number, annotations?: string) =>
    `Total: ${interactions} Interactions \n Annotated: ${annotations}`
};

const { title, score, legends, graphStyles, graphSizes, distribution } = constants;

export const AnnotationOvertimeGraph = (props: AnnotationOvertimeGraphProps) => {
  const { selectedVersionId, envType, timestamp } = props;

  const { push } = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [annotationMonitorData, setAnnotationMonitorData] = useState<AnnotationsMonitorSchema>();

  useEffect(() => {
    setIsLoading(true);
    getAnnotationsMonitorApiV1ApplicationVersionsApplicationVersionIdGetAnnotationsMonitorPost(
      selectedVersionId,
      {
        end_time_epoch: timestamp.end || dayjs().unix(),
        start_time_epoch: timestamp.start || dayjs().subtract(1, 'month').unix(),
        tz: `${Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone}`
      },
      { env_type: envType }
    ).then(res => {
      setIsLoading(false);
      setAnnotationMonitorData(res);
    });
  }, [envType, timestamp?.start, timestamp?.end, selectedVersionId]);

  const labels = Array?.isArray(annotationMonitorData?.window_list)
    ? annotationMonitorData?.window_list?.map(window =>
        dayjs(window).format(annotationMonitorData?.frequency === 'HOUR' ? 'DD/MM LT' : 'll')
      )
    : [];

  const barData = Array.isArray(annotationMonitorData?.values)
    ? annotationMonitorData?.values?.map(val => [
        Number(val?.per_type_results?.good?.user?.amount || 0) + Number(val?.per_type_results?.bad?.user?.amount || 0),
        Number(val?.per_type_results?.good?.estimated?.amount || 0) +
          Number(val?.per_type_results?.bad?.estimated?.amount || 0),
        Number(val?.per_type_results?.unknown?.user?.amount || 0) +
          Number(val?.per_type_results?.unknown?.estimated?.amount || 0)
      ])
    : [];

  const lineData = Array.isArray(annotationMonitorData?.values)
    ? annotationMonitorData?.values?.map(val => Number(val?.score))
    : [];

  const totalInteractionsCount = barData?.reduce((acc, val) => acc + val.reduce((acc, val) => acc + val, 0), 0);
  const totalGoodInteractionsCount = annotationMonitorData?.values?.reduce(
    (acc, val) =>
      acc +
      (Number(val?.per_type_results?.good?.user?.amount || 0) +
        Number(val?.per_type_results?.good?.estimated?.amount || 0)),
    0
  );

  const totalUserAnnotationsCount = annotationMonitorData?.values?.reduce(
    (acc, val) =>
      acc +
      (Number(val?.per_type_results?.good?.user?.amount || 0) + Number(val?.per_type_results?.bad?.user?.amount || 0)),
    0
  );

  const totalScore = getPercentage(Number(totalGoodInteractionsCount) / totalInteractionsCount);
  const annotationsPercentage = getPercentage(Number(totalUserAnnotationsCount) / totalInteractionsCount);

  const isNoData = lineData?.every(val => Number.isNaN(val));

  return (
    <AnnotationOvertimeGraphContainer type="card">
      <AnnotationOvertimeGraphHeaderContainer>
        <StyledText text={title} type="h2" />
        <StyledText
          type="small"
          text={
            <>
              {distribution(totalInteractionsCount, annotationsPercentage)}
              <span>
                {score} {totalScore}
              </span>
            </>
          }
        />
      </AnnotationOvertimeGraphHeaderContainer>
      {isLoading ? (
        <StyledLoader />
      ) : (
        <>
          {isNoData && <OvertimeGraphNoDataImage />}
          <StyledComboChart
            labels={labels}
            barData={barData}
            lineData={lineData}
            barLegends={legends}
            style={graphStyles}
            barWidth={graphSizes.width}
            barHeight={graphSizes.height}
            rawData={annotationMonitorData?.values}
            handleRedirect={push}
          />
        </>
      )}
    </AnnotationOvertimeGraphContainer>
  );
};
